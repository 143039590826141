import { readonly } from 'vue';
import { isValid, Model } from './model';

export class PlayerEntryHistory extends Model {
    isValid() {
        return isValid(PlayerEntryHistory.schema, this);
    }
}

PlayerEntryHistory.layout = {
    children: [      
        {
            children: ['playerFirstName', 'playerLastName']
        },
        {
            children: ['tournamentName', 'tournamentYear']
        },
        {
            children: ['entryMode', 'tournamentLevel']
        },
        {
            children: ['singlesRank', 'doublesRank']
        },
        {
            children: ['withDrawalType', 'zero_Point'] 
        },
        {
            children: ['withDrawalDate', 'withDrawalReason']
        },
    ],
};

PlayerEntryHistory.schema = {
    fields: {
        recKey: { type: 'number', displayName: 'Rec Key', readonly: true },
        playerNumber: { type: 'player', displayName: 'Player Id', readonly: true },
        playerFirstName: { type: 'string', displayName: 'First Name', readonly: true },
        playerLastName: { type: 'string', displayName: 'Last Name', readonly: true },
        entryDate: { type: 'us-date', displayName: 'Entry Date', readonly: true, sortable: true },
        entryType: { type: 'string', displayName: 'Entry Type', readonly: true },
        entryStatus: { type: 'string', displayName: 'Entry Status', readonly: true },        
        entryMode: {
            displayName: 'Draw Type',
            type: 'string',
            group: 'general',            
            readonly: true, 
            lookup: [
                { id: 'M', displayName: 'Main' },
                { id: 'Q', displayName: 'Quali' },
            ],
        },
        tournamentLevel: {
            type: 'string',
            displayName: 'Tournament Level',
            required: false,
            sortable: true, clearable: true,
            readonly: true,             
            lookup: [
                { id: 'GS', displayName: 'GrandSlam' },
                { id: 'CH', displayName: 'Championship' },
                { id: 'PM', displayName: 'WTA 1000 (PM)' },
                { id: 'P5', displayName: 'WTA 1000 (P5)' },
                { id: 'P', displayName: 'WTA 500 (P)' },
                { id: 'IS', displayName: 'WTA 250 (IS)' },
                { id: 'C', displayName: 'WTA 125' },
                { id: 'R1', displayName: 'FedCupRound1' },
                { id: 'R2', displayName: 'FedCupRound2' },
                { id: 'R3', displayName: 'FedCupSemiFinal' },
                { id: 'R4', displayName: 'FedCupFinal' },
                { id: 'I', displayName: 'TierI' },
                { id: 'II', displayName: 'TierII' },
                { id: 'III', displayName: 'TierIII' },
                { id: 'IV', displayName: 'TierIV' },
                { id: 'V', displayName: 'TierV' },
                { id: 'IN', displayName: 'IN' },
                { id: 'IVA', displayName: 'IVA' },
                { id: 'IVB', displayName: 'IVB' },
            ],
        },
        acceptedDate: { type: 'us-date', displayName: 'Accepted Date', readonly: true },
        tournamentNumber: { type: 'number', displayName: 'Tournament Id', readonly: true },
        tournamentYear: { type: 'number', displayName: 'Tournament Year', readonly: true, sortable: true },
        tournamentName: { type: 'string', displayName: 'Tournament Name', readonly: true, sortable: true },
        withDrawalType: {
            displayName: 'Withdrawal Type',
            type: 'string',
            group: 'general',            
            required: true,
            clearable: true,
            lookup: [                
                { id: ' ', displayName: 'None' },
                { id: 'EX', displayName: 'Excused' },
                { id: 'PM', displayName: 'Prize Money' },
                { id: 'FN', displayName: 'Fine' },
                { id: 'CN', displayName: 'Continuing' },
            ],
        },
        zero_Point: {
            displayName: 'Zero - Point',
            type: 'string',
            group: 'general',            
            required: true,
            clearable: true,
            lookup: [
                { id: true, displayName: 'Yes' },
                { id: false, displayName: 'No' },                
            ],
        },
        withDrawalDate: { type: 'us-date', displayName: 'Withdrawal Date', required: true},
        withDrawalReason: { type: 'multiline', displayName: 'Withdrawal Reason', required: true},        
        singlesRank: { type: 'number', displayName: 'Singles Rank', readonly: true},
        doublesRank: { type: 'number', displayName: 'Doubles Rank', readonly: true},
        startDate: { type: 'us-date', displayName: 'Tournament Start Date', readonly: true },
        entryPreference: { type: 'string', displayName: 'Entry Preference', readonly: true },
    }
}