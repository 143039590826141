import { isValid, Model } from './model';
import { icoCountries } from '../utils/country-codes';

export class Player extends Model {
    constructor(data) {
        if (data && !data.currency) {
            data.currency = 'USD';
        }
        super(data);
    }
    isValid() {
        return isValid(Player.schema, this);
    }
    isValidForEdit() {
        return isValid(Player.schema, this);
    }
}

Player.layout = {
    title: 'Basic Information',
    children: [
        {
            children: ['id', 'inactive', { type: 'spacer', width: 2 }],
        },
        {
            children: ['firstName', 'middleName', 'lastName', { type: 'spacer' }],
        },
        {
            children: ['title', 'nationality', 'secondNationality', { type: 'spacer' }],
        },
        {
            children: ['email', 'dateOfBirth', 'playerAge', { type: 'spacer' }],
        },
        {
            title: 'Membership Information',
            children: ['proStatus', 'proDate', { type: 'spacer', width: 2 }],
        },
    ],
};

Player.layoutMembership = {
    title: 'Membership Information',
    children: [
        {
            children: ['memberType', 'eligibleMembership'],
        },
        {
            children: ['benefitYear', 'paymentDate', 'paymentType', { type: 'spacer', width: 1 }],
        },
        {
            children: ['membershipCost', 'insuranceCost', 'totalCost'],
        },
        {
            children: ['disabilityInsuranceCost', 'disabilityInsuranceCostPaymentDate'],
        },
    ],
};

Player.layoutMembershipHistory = {
    title: 'Membership Level History',    
};

Player.layoutDetails = {
    title: 'Detailed Information',
    children: [
        {
            children: ['addressLine1', 'addressLine2', 'addressLine3', 'addressLine4'],
        },
        {
            children: ['city', 'state', 'nationCode', 'mailCode'],
        },
        {
            title: 'Contact',
            children: ['phone1', 'phone1Type', 'phone2', 'phone2Type'],
        },
        {
            title: 'Other',
            children: ['playerComment', 'beneficiary'],
        },
        {
            title: 'Development',
            children: ['currentPhase', { type: 'spacer', width: 3 }],
        },
        {
            title: 'Wildcards',
            children: ['wcUsedQualiSingles', 'wcUsedQualiDoubles', 'wcUsedMainSingles', 'wcUsedMainDoubles'],
        },
        {
            title: 'Ranking',
            children: ['singlesRank', 'doublesRank', 'specialRank', 'specialDoublesRank'],
        },
    ],
};

Player.layoutDates = {
    title: 'Administration',
    children: [
        {
            children: ['proofOfId', 'proofOfEducation', { type: 'spacer', width: 2 }],
        },
        {
            children: ['playerAnnualFormDate', 'playerAnnualFormName', 'playerAnnualFormRelation', { type: 'spacer', width: 1 }],
        },
        {
            children: ['integrityTrainingCompDate', 'integrityTrainingExpirationDate', { type: 'spacer', width: 2 }],
        },
    ],
};

Player.layoutDatesTwo = {
    title: 'Performance Health',
    children: [
        {
            children: ['playerHipaaFormDate', 'playerHipaaFormName', 'playerHipaaFormRelation', 'playerHipaaFormEmail'],
        },
        {
            children: ['firstPhysical', 'annualPhysical', 'minorMedical', { type: 'spacer', width: 1 }],
        },
        {
            children: ['disabilityInsurance', 'insuranceType', 'joinedDate', { type: 'spacer', width: 1 }],
        },
    ],
};

Player.layoutPersons = {
    children: [
        {
            title: 'Coach',
            children: ['coachName', 'coachEmail', 'coachPhone', { type: 'spacer' }],
        },
        {
            title: 'Agent',
            children: ['agentName', 'agentEmail', 'agentPhone', 'agentAuthorizedForChanges'],
        },
        {
            title: 'Registered Coach',
            children: ['registeredCoachName', 'registeredCoachEmail', 'registeredCoachPhone', 'registeredCoachType'],
        },
        {
            title: 'Financial Rep',
            children: ['financialRepName', 'financialRepEmail', 'financialRepPhone', 'financialRepAuthorizedForChanges'],
        },
    ],
};

Player.layoutPaymentGeneral = {
    title: 'General',
    children: [
        {
            children: ['paymentAddress1', 'paymentAddress2', 'paymentCity'],
        },
        {
            children: ['paymentPreference', 'paymentEmail', 'passportCity'],
        },
        {
            children: ['prefBankUsd', 'prefBankEur', 'prefBankOther'],
        },
    ],
};

Player.layoutPayment1 = {
    title: 'Primary bank',
    children: [
        {
            children: ['bankAccountName1', 'bankAccount1', 'bankIban1', 'preferredCurrency1'],
        },
        {
            children: ['bankName1', 'bankAddress1'],
        },
        {
            children: ['bankCity1', 'bankPostalCode1', 'bankCountry1', 'bankOther1'],
        },
        {
            children: ['bankSwft1', 'bankCode1'],
        },
    ],
};

Player.layoutPayment2 = {
    title: 'Bank 2',
    children: [
        {
            children: ['bankAccountName2', 'bankAccount2', 'bankIban2', 'preferredCurrency2'],
        },
        {
            children: ['bankName2', 'bankAddress2'],
        },
        {
            children: ['bankCity2', 'bankPostalCode2', 'bankCountry2', 'bankOther2'],
        },
        {
            children: ['bankSwft2', 'bankCode2'],
        },
    ],
};

Player.layoutPayment3 = {
    title: 'Bank 3',
    children: [
        {
            children: ['bankAccountName3', 'bankAccount3', 'bankIban3', 'preferredCurrency3'],
        },
        {
            children: ['bankName3', 'bankAddress3'],
        },
        {
            children: ['bankCity3', 'bankPostalCode3', 'bankCountry3', 'bankOther3'],
        },
        {
            children: ['bankSwft3', 'bankCode3'],
        },
    ],
};

Player.layoutPayment4 = {
    title: 'Bank 4',
    children: [
        {
            children: ['bankAccountName4', 'bankAccount4', 'bankIban4', 'preferredCurrency4'],
        },
        {
            children: ['bankName4', 'bankAddress4'],
        },
        {
            children: ['bankCity4', 'bankPostalCode4', 'bankCountry4', 'bankOther4'],
        },
        {
            children: ['bankSwft4', 'bankCode4'],
        },
    ],
};

Player.layoutEarnings = {
    title: 'Player Earnings',
    children: [
        {
            children: ['prizeYtd', 'prizePrior', 'prizeCareer'],
        },
        {
            children: ['prizeYtdSingles', 'prizeYtdDoubles', { type: 'spacer', width: 1 }],
        },
        {
            children: ['prizeYtdOther', 'prizeYtdMixed', { type: 'spacer', width: 1 }],
        },
    ],
};

Player.layoutDevelopmentContact = {
    children: [
        {
            children: ['id', 'firstName', 'middleName', 'lastName'],
        },
        {
            children: ['title', 'nationality', 'secondNationality', { type: 'spacer' }],
        },
        {
            children: ['email', 'dateOfBirth', 'playerAge', { type: 'spacer' }],
        },
        {
            children: ['addressLine1', 'addressLine2', 'addressLine3', 'addressLine4'],
        },
        {
            children: ['city', 'state', 'nationCode', 'mailCode'],
        },
        {
            children: ['phone1', 'phone1Type', 'phone2', 'phone2Type'],
        },
    ],
};

Player.layoutAddPlayer = {
    title: 'Basic Information',
    children: [
        {
            children: ['firstName', 'lastName', { type: 'spacer' }],
        },
        {
            children: ['dateOfBirth', 'nationality', 'email'],
        },
    ],
};

Player.layoutDevelopmentCompletedRequriements = {
    children: [
        {
            children: ['completedRequirements'],
        },
    ],
};

Player.layoutDevelopmentOutstandingRequriements = {
    children: [
        {
            children: ['outstandingRequirements'],
        },
    ],
};

Player.layoutDevelopmentOverdueRequriements = {
    children: [
        {
            children: ['overdueRequirements'],
        },
    ],
};

Player.schema = {
    fields: {
        //
        // CONTACTS
        //
        id: { displayName: 'Id', sortable: true, type: 'number', group: 'general', readonly: true },
        lastName: { type: 'string', displayName: 'Last name', sortable: true, group: 'general', required: true, maxLength: 40, specialCharacterAllowed: false },
        firstName: {
            type: 'string',
            displayName: 'First name',
            sortable: true,
            group: 'general',
            required: true,
            maxLength: 15,
            specialCharacterAllowed: false,
        },
        middleName: { type: 'string', displayName: 'Middle name', group: 'general', clearable: true, maxLength: 15 },
        title: { type: 'string', displayName: 'Title', group: 'general', clearable: true, maxLength: 6 },
        recType: { type: 'string', displayName: 'Rec Type', maxLength: 2, clearable: true },
        comment: { type: 'string', displayName: 'Comment', maxLength: 60, clearable: true },
        taxId: { type: 'string', displayName: 'Tax Id', maxLength: 15, clearable: true },
        age: { type: 'number', displayName: 'Age', clearable: true },
        daysAfter: { type: 'number', displayName: 'DaysA fter', clearable: true },
        daysTo: { type: 'number', displayName: 'Days To', clearable: true },
        rank: { type: 'number', displayName: 'Rank', clearable: true },
        rankDate: { type: 'date', displayName: 'Rank Date', clearable: true },
        userId: { type: 'string', displayName: 'User Id', maxLength: 10, clearable: true },
        password: { type: 'string', displayName: 'Password', maxLength: 10, clearable: true },
        passChanged: { type: 'date', displayName: 'Pass Changed', clearable: true },
        isAdmin: { type: 'number', displayName: 'IsAdmin', clearable: true },
        pstBDate: { type: 'date', displayName: 'Birthdate', clearable: true },
        pstNation: { type: 'string', displayName: 'Nationality', maxLength: 10, clearable: true },

        //
        // CONTACT_DATA
        //
        contactNbr: { type: 'number', displayName: 'ContactNbr', clearable: true },
        contactType: { type: 'string', displayName: 'ContactType', maxLength: 4, clearable: true },
        addressLine1: { displayName: 'Address line 1', type: 'string', clearable: true, maxLength: 40 },
        addressLine2: { displayName: 'Address line 2', type: 'string', clearable: true, maxLength: 40 },
        addressLine3: { displayName: 'Address line 3', type: 'string', clearable: true, maxLength: 40 },
        addressLine4: { displayName: 'Address line 4', type: 'string', clearable: true, maxLength: 40 },
        phone1: { type: 'string', displayName: 'Phone 1', maxLength: 15, clearable: true },
        phone1Type: { type: 'string', displayName: 'Phone 1 Type', maxLength: 15, clearable: true },
        phone2: { type: 'string', displayName: 'Phone 2', maxLength: 15, clearable: true },
        phone2Type: { type: 'string', displayName: 'Phone 2 Type', maxLength: 15, clearable: true },
        phone3: { type: 'string', displayName: 'Phone3', maxLength: 15, clearable: true },
        phone3Type: { type: 'string', displayName: 'Phone3Type', maxLength: 15, clearable: true },
        phoneFax: { type: 'string', displayName: 'Fax', maxLength: 15, clearable: true },
        mailCode: { type: 'string', displayName: 'Mail Code', maxLength: 15, clearable: true },
        status: { type: 'string', displayName: 'Status', maxLength: 4, clearable: true },
        statusDate: { type: 'date', displayName: 'StatusDate', clearable: true },
        playerComment: { type: 'string', displayName: 'Comment', group: 'general', maxLength: 60, clearable: true },
        email: { displayName: 'Email address', type: 'email', required: true, group: 'general', clearable: true, maxLength: 40 },
        nationCode: { type: 'string', displayName: 'Nation code', clearable: true, maxLength: 4 },
        agentName: { displayName: 'Agent Name', type: 'string', clearable: true, maxLength: 40 },
        agentEmail: { displayName: 'Agent Email', type: 'email', clearable: true, maxLength: 40 },
        coachName: { displayName: 'Coach Name', type: 'string', clearable: true, maxLength: 40 },
        coachEmail: { displayName: 'Coach Email', type: 'email', clearable: true, maxLength: 40 },
        city: { type: 'string', displayName: 'City', group: 'general', clearable: true, maxLength: 40 },
        state: { type: 'string', displayName: 'State', clearable: true, maxLength: 2 },
        fax: { type: 'string', displayName: 'Fax', maxLength: 25, clearable: true },
        agentPhone: { displayName: 'Agent Phone', type: 'string', clearable: true, maxLength: 15 },
        coachPhone: { displayName: 'Coach Phone', type: 'string', clearable: true, maxLength: 15 },
        financialRepName: { displayName: 'Financial Rep Name', type: 'string', clearable: true, maxLength: 40 },
        financialRepEmail: { displayName: 'Financial Rep Email', type: 'email', clearable: true, maxLength: 40 },
        financialRepPhone: { displayName: 'Financial Rep Phone', type: 'string', clearable: true, maxLength: 15 },
        financialRepAuthorizedForChanges: {
            displayName: 'Authorized For Changes',
            type: 'string',
            clearable: true,
            lookup: [
                { id: 'Y', displayName: 'Yes' },
                { id: 'N', displayName: 'No' },
            ],
        },
        agentAuthorizedForChanges: {
            displayName: 'Authorized For Changes',
            type: 'string',
            clearable: true,
            lookup: [
                { id: 'Y', displayName: 'Yes' },
                { id: 'N', displayName: 'No' },
            ],
        },
        registeredCoachName: { displayName: 'Registered Coach Name', type: 'string', maxLength: 40, clearable: true },
        registeredCoachEmail: { displayName: 'Registered Coach Email', type: 'email', maxLength: 40, clearable: true },
        registeredCoachPhone: { displayName: 'Registered Coach Phone', type: 'string', maxLength: 15, clearable: true },
        registeredCoachType: { displayName: 'Registered Coach Type', type: 'string', maxLength: 1, clearable: true },

        //
        // PLAYER_MASTER
        //
        dateOfBirth: { type: 'date', displayName: 'Date of birth', sortable: true, group: 'general', clearable: true, required: true },
        birthCity: { type: 'string', displayName: 'Birth City', maxLength: 30, clearable: true },
        birthCountry: { type: 'string', displayName: 'Birth Country', maxLength: 4, clearable: true },
        proStatus: { displayName: 'PRO Status', type: 'string', clearable: true },
        proDate: { displayName: 'PRO Date', type: 'date', clearable: true },
        witaAcademy: { displayName: 'Wita Academy', type: 'string', clearable: true },
        memberType: {
            displayName: 'Current Year Membership',
            type: 'string',
            required: false,
            sysCode: 'MBR_TYPE',
            lookup: [
                { id: 'S', displayName: 'S' },
                { id: 'A', displayName: 'A' },
                { id: 'X', displayName: 'X' },
                { id: 'F', displayName: 'F' },
            ],
        },
        joinedDate: { displayName: 'Join Date', type: 'date', clearable: true },
        nationality: {
            displayName: 'Nationality',
            type: 'nationality',
            group: 'general',
            required: true,
            sortable: true,
            lookup: Object.keys(icoCountries),
            clearable: true,
            specialCharacterAllowed: false,
        },
        statusFlag: { type: 'string', displayName: 'Status Flag', maxLength: 2, clearable: true },
        contract1: { displayName: 'Contract 1', type: 'string', maxLength: 2, clearable: true },
        contract2: { displayName: 'Contract 2', type: 'string', maxLength: 2, clearable: true },
        contract3: { displayName: 'Contract 3', type: 'string', maxLength: 2, clearable: true },
        contract4: { displayName: 'Contract 4', type: 'string', maxLength: 2, clearable: true },
        contract5: { displayName: 'Contract 5', type: 'string', maxLength: 2, clearable: true },
        specialRankSingles: { displayName: 'Singles Special Rank', type: 'number', clearable: true },
        specialRankSinglesStart: { displayName: 'Singles Special Rank Start', type: 'date', clearable: true },
        specialRankSinglesEnd: { displayName: 'Singles Special Rank End', type: 'date', clearable: true },
        specialRankSinglesAllowed: { displayName: 'Singles Special Rank Allowed', type: 'number', clearable: true },
        specialRankSinglesPlayed: { displayName: 'Singles Special Rank Played', type: 'number', clearable: true },
        specialRankDoubles: { displayName: 'Doubles Special Rank', type: 'number', clearable: true },
        specialRankDoublesStart: { displayName: 'Doubles Special Rank Start', type: 'date', clearable: true },
        specialRankDoublesEnd: { displayName: 'Doubles Special Rank End', type: 'date', clearable: true },
        specialRankDoublesAllowed: { displayName: 'Doubles Special Rank Allowed', type: 'number', clearable: true },
        specialRankDoublesPlayed: { displayName: 'Doubles Special Rank Played', type: 'number', clearable: true },
        secondNationality: { displayName: '2nd Nationality', type: 'nationality', lookup: Object.keys(icoCountries), clearable: true },
        wildCards: { type: 'number', displayName: 'Wildcards', clearable: true },
        wildCardsClearDate: { type: 'date', displayName: 'Wildcards Clear Date', clearable: true },
        playerMasterAge: { type: 'number', displayName: 'Age', clearable: true },
        ageDf: { type: 'number', displayName: 'Age days from', clearable: true },
        ageDt: { type: 'number', displayName: 'Age days to', clearable: true },
        inactive: { displayName: 'Inactive', type: 'string', sysCode: 'ACTIVE_CODE', required: true },
        specialRankGsSinglesAllowed: { displayName: 'Singles Special Rank GS Allowed', type: 'number', clearable: true },
        specialRankGsSinglesPlayed: { displayName: 'Singles Special Rank GS Played', type: 'number', clearable: true },
        specialRankGsDoublesAllowed: { displayName: 'Doubles Special Rank GS Allowed', type: 'number', clearable: true },
        specialRankGsDoublesPlayed: { displayName: 'Doubles Special Rank GS Played', type: 'number', clearable: true },
        wcUsedQualiSingles: { type: 'number', displayName: 'Wildcards used Quali Singles', clearable: true },
        wcUsedQualiDoubles: { type: 'number', displayName: 'Wildcards used Quali Doubles', clearable: true },
        wcUsedMainSingles: { type: 'number', displayName: 'Wildcards used Main Singles', clearable: true },
        wcUsedMainDoubles: { type: 'number', displayName: 'Wildcards used Main Doubles', clearable: true },
        specialRankTermInDays: { displayName: 'Special Rank Term in days', type: 'number', clearable: true },
        specialRankSetUpDate: { displayName: 'Special Rank Set Up', type: 'date', clearable: true },
        highestRank: { displayName: 'Highest Singels Rank', type: 'number', readonly: true, clearable: true },
        highestRankDate: { displayName: 'Highest Singles Rank Date', type: 'date', readonly: true, clearable: true },
        highestDrank: { displayName: 'Highest Doubles Rank', type: 'number', readonly: true, clearable: true },
        highestDrankDate: { displayName: 'Highest Doubles Rank Date', type: 'date', readonly: true, clearable: true },
        prizeCareer: { displayName: 'Total Career Earnings', type: 'currency', readonly: true },
        prizePrior: { displayName: 'Total Prior Earnings', type: 'currency', readonly: true },
        prizeYtd: { displayName: 'Total YTD Earnings', type: 'currency', readonly: true },
        prizeYtdSingles: { displayName: 'YTD Singles Earnings', type: 'currency', readonly: true },
        prizeYtdDoubles: { displayName: 'YTD Doubles Earnings', type: 'currency', readonly: true },
        prizeYtdMixed: { displayName: 'YTD Mixed Earnings', type: 'currency', readonly: true },
        prizeYtdOther: { displayName: 'YTD Bonus Earnings', type: 'currency', readonly: true },
        ytdSinglesWins: { displayName: 'Ytd Singles Wins', type: 'number', readonly: true, clearable: true },
        ytdSinglesLosses: { displayName: 'Ytd Singles Losses', type: 'number', readonly: true, clearable: true },
        careerSinglesWins: { displayName: 'Career Singles Wins', type: 'number', readonly: true, clearable: true },
        careerSinglesLosses: { displayName: 'Career Singles Losses', type: 'number', readonly: true, clearable: true },
        careerTiebreakWins: { displayName: 'Career Tiebreak Wins', type: 'number', readonly: true, clearable: true },
        careerTiebreakLosses: { displayName: 'Career Tiebreak Losses', type: 'number', readonly: true, clearable: true },
        career3SetWins: { displayName: 'Career 3Set Wins', type: 'number', readonly: true, clearable: true },
        career3SetLosses: { displayName: 'Career 3Set Losses', type: 'number', readonly: true, clearable: true },
        careerTournamentSinglesWins: { displayName: 'Career Tournament Singles Wins', type: 'number', readonly: true, clearable: true },
        careerTournamentDoublesWins: { displayName: 'Career Tournament Doubles Wins', type: 'number', readonly: true, clearable: true },
        region: { displayName: 'Region', type: 'string', maxLength: 4, clearable: true },
        regionChanged: { displayName: 'Region Changed', type: 'string', maxLength: 60, clearable: true },
        insuranceCost: { displayName: 'Insurance Payment', type: 'currency', clearable: true },
        membershipCost: { displayName: 'Membership Payment', type: 'currency', clearable: true },
        insuranceType: { displayName: 'Insurance Type', type: 'string', clearable: true, maxLength: 4 },
        paymentType: { displayName: 'Payment Type', type: 'string', clearable: true, maxLength: 40 },
        paymentDate: { displayName: 'Payment Date', type: 'date', clearable: true },
        instPaymentDate: { displayName: 'Insurance Payment Date', type: 'date', clearable: true },
        payProcDate: { displayName: 'Payment Process Date', type: 'date', clearable: true },
        idCard: { displayName: 'ID Card No', type: 'string', clearable: true, maxLength: 40 },
        membershipComment: { displayName: 'Membership Comment', type: 'multiline', clearable: true, maxLength: 80 },
        pensionEntry: { displayName: 'Pension Entry', type: 'string', clearable: true, maxLength: 4 },
        pensionVested: { displayName: 'Pension Vested', type: 'string', clearable: true, maxLength: 4 },
        pensionContrib: { displayName: 'Pension Contrib', type: 'string', clearable: true, maxLength: 80 },
        pensionService: { displayName: 'Pension Service', type: 'string', clearable: true, maxLength: 80 },
        payThruDate: { displayName: 'Pay Thru Date', type: 'date', clearable: true },
        pensCurAmt: { displayName: 'Pens Current Amt', type: 'decimal', clearable: true },
        pensPrAmt: { displayName: 'Pens Previous Amt', type: 'decimal', clearable: true },
        insuranceTypeSel: { displayName: 'Insurance Type Sel', type: 'string', clearable: true, maxLength: 4 },
        disabilityInsuranceCost: { displayName: 'Disability Insurance Amount', type: 'decimal', clearable: true },
        disabilityInsuranceCostPaymentDate: { displayName: 'Disability Insurance Payment Date', type: 'date', clearable: true },
        memberTypeNextYear: {
            displayName: 'Next Year Membership',
            type: 'string',
            sysCode: 'MBR_TYPE',
            clearable: true,
            maxLength: 4,
            lookup: [
                { id: 'S', displayName: 'S' },
                { id: 'A', displayName: 'A' },
                { id: 'X', displayName: 'X' },
                { id: 'F', displayName: 'F' },
            ],
        },
        benefitYear: { displayName: 'Benefit Year', type: 'string', clearable: true, maxLength: 4 },
        minorMedical: { displayName: 'Minor Medical', type: 'date', clearable: true },
        antiDoping: { displayName: 'Anti Doping', type: 'date', clearable: true },
        proofOfId: { displayName: 'Proof Of Id', type: 'date', clearable: true },
        agreementDate: { displayName: 'Player Annual Form', type: 'date', clearable: true },
        signature: { displayName: 'Signed by', type: 'string', clearable: true, maxLength: 80 },
        taxPaymentAddress1: { displayName: 'Tax Address 1', type: 'string', clearable: true, maxLength: 50 },
        taxPaymentAddress2: { displayName: 'Tax Address 2', type: 'string', clearable: true, maxLength: 50 },
        taxPaymentCity: { displayName: 'Tax City', type: 'string', clearable: true, maxLength: 50 },
        taxPaymentState: { displayName: 'Tax State', type: 'string', clearable: true, maxLength: 20 },
        taxPaymentCountry: { displayName: 'Tax Country', type: 'string', clearable: true, maxLength: 30 },
        passportNo: { displayName: 'Passport No', type: 'string', clearable: true, maxLength: 20 },
        passportCity: { type: 'string', displayName: 'Payment Country', clearable: true, maxLength: 50 }, // TODO: Check if correct mapping is applied!
        passportIssueDate: { displayName: 'Passport Issuance', type: 'date', clearable: true },
        passportExpireDate: { displayName: 'Passport Expiry', type: 'date', clearable: true },
        beneficiary: { displayName: 'Beneficiary', type: 'string', clearable: true, maxLength: 50 },
        paymentPreference: { type: 'string', displayName: 'Payment Preference', clearable: true, maxLength: 10 },
        bankAccount1: { type: 'password', displayName: 'Bank Account Number', clearable: true, maxLength: 75 },
        bankAccount2: { type: 'password', displayName: 'Bank Account Number', clearable: true, maxLength: 75 },
        bankAccount3: { type: 'password', displayName: 'Bank Account Number', clearable: true, maxLength: 75 },
        bankAccount4: { type: 'password', displayName: 'Bank Account Number', clearable: true, maxLength: 75 },
        preferredCurrency1: { type: 'string', displayName: 'Preferred Currency', clearable: true, maxLength: 20 },
        preferredCurrency2: { type: 'string', displayName: 'Preferred Currency', clearable: true, maxLength: 20 },
        preferredCurrency3: { type: 'string', displayName: 'Preferred Currency', clearable: true, maxLength: 20 },
        preferredCurrency4: { type: 'string', displayName: 'Preferred Currency', clearable: true, maxLength: 20 },
        bankAccountName1: { type: 'string', displayName: 'Bank Account Name', clearable: true, maxLength: 50 },
        bankPercent1: { displayName: 'Bank Percent', type: 'decimal', clearable: true },
        bankName1: { type: 'string', displayName: 'Bank Name', clearable: true, maxLength: 50 },
        bankAddress1: { type: 'string', displayName: 'Bank Address', clearable: true, maxLength: 50 },
        bankCity1: { type: 'string', displayName: 'Bank City', clearable: true, maxLength: 50 },
        bankPostalCode1: { type: 'string', displayName: 'Bank Postal Code', clearable: true, maxLength: 10 },
        bankCountry1: { type: 'string', displayName: 'Bank Country', clearable: true, maxLength: 30 },
        bankSwft1: { type: 'string', displayName: 'Bank Swft', clearable: true, maxLength: 20 },
        bankCode1: { type: 'string', displayName: 'Bank Code', clearable: true, maxLength: 20 },
        bankIban1: { type: 'password', displayName: 'Bank Iban', clearable: true, maxLength: 30 },
        bankOther1: { type: 'string', displayName: 'Bank Other 1', clearable: true, maxLength: 255 },
        bankAccountName2: { type: 'string', displayName: 'Bank Account Name', clearable: true, maxLength: 50 },
        bankPercent2: { displayName: 'Bank Percent', type: 'decimal', clearable: true },
        bankName2: { type: 'string', displayName: 'Bank Name', clearable: true, maxLength: 50 },
        bankAddress2: { type: 'string', displayName: 'Bank Address', clearable: true, maxLength: 50 },
        bankCity2: { type: 'string', displayName: 'Bank City', clearable: true, maxLength: 50 },
        bankPostalCode2: { type: 'string', displayName: 'Bank Postal Code', clearable: true, maxLength: 10 },
        bankCountry2: { type: 'string', displayName: 'Bank Country', clearable: true, maxLength: 30 },
        bankSwft2: { type: 'string', displayName: 'Bank Swft', clearable: true, maxLength: 20 },
        bankCode2: { type: 'string', displayName: 'Bank Code', clearable: true, maxLength: 20 },
        bankIban2: { type: 'password', displayName: 'Bank Iban', clearable: true, maxLength: 30 },
        bankOther2: { type: 'string', displayName: 'Bank Other 2', clearable: true, maxLength: 255 },
        bankAccountName3: { type: 'string', displayName: 'Bank Account Name', clearable: true, maxLength: 50 },
        bankPercent3: { displayName: 'Bank Percent', type: 'decimal', clearable: true },
        bankName3: { type: 'string', displayName: 'Bank Name', clearable: true, maxLength: 50 },
        bankAddress3: { type: 'string', displayName: 'Bank Address', clearable: true, maxLength: 50 },
        bankCity3: { type: 'string', displayName: 'Bank City', clearable: true, maxLength: 50 },
        bankPostalCode3: { type: 'string', displayName: 'Bank Postal Code', clearable: true, maxLength: 10 },
        bankCountry3: { type: 'string', displayName: 'Bank Country', clearable: true, maxLength: 30 },
        bankSwft3: { type: 'string', displayName: 'Bank Swft', clearable: true, maxLength: 20 },
        bankCode3: { type: 'string', displayName: 'Bank Code', clearable: true, maxLength: 20 },
        bankIban3: { type: 'password', displayName: 'Bank Iban', clearable: true, maxLength: 30 },
        bankOther3: { type: 'string', displayName: 'Bank Other 3', clearable: true, maxLength: 255 },
        bankAccountName4: { type: 'string', displayName: 'Bank Account Name', clearable: true, maxLength: 50 },
        bankPercent4: { displayName: 'Bank Percent', type: 'decimal', clearable: true },
        bankName4: { type: 'string', displayName: 'Bank Name', clearable: true, maxLength: 50 },
        bankAddress4: { type: 'string', displayName: 'Bank Address', clearable: true, maxLength: 50 },
        bankCity4: { type: 'string', displayName: 'Bank City', clearable: true, maxLength: 50 },
        bankPostalCode4: { type: 'string', displayName: 'Bank Postal Code', clearable: true, maxLength: 10 },
        bankCountry4: { type: 'string', displayName: 'Bank Country', clearable: true, maxLength: 30 },
        bankSwft4: { type: 'string', displayName: 'Bank Swft', clearable: true, maxLength: 20 },
        bankCode4: { type: 'string', displayName: 'Bank Code', clearable: true, maxLength: 20 },
        bankIban4: { type: 'password', displayName: 'Bank Iban', clearable: true, maxLength: 30 },
        bankOther4: { type: 'string', displayName: 'Bank Other 4', clearable: true, maxLength: 255 },
        paymentAddress1: { type: 'string', displayName: 'Payment Address 1', clearable: true, maxLength: 50 },
        paymentAddress2: { type: 'string', displayName: 'Payment Address 2', clearable: true, maxLength: 50 },
        paymentCity: { type: 'string', displayName: 'Payment City', clearable: true, maxLength: 50 },
        playerPaymentState: { displayName: 'Player Payment State', type: 'string', clearable: true, maxLength: 20 },
        playerPaymentCountry: { displayName: 'Country', type: 'string', clearable: true, maxLength: 30 },
        playerPaymentPostalCode: { displayName: 'Postal Code', type: 'string', clearable: true, maxLength: 10 },
        paymentEmail: { type: 'email', displayName: 'Payment Email', clearable: true, maxLength: 80 },
        hippa: { displayName: 'HIPPA', type: 'date', clearable: true },
        pifDate: { displayName: 'PIF', type: 'date', clearable: true },
        proofOfEducation: { displayName: 'Proof Of Education', type: 'date', clearable: true },
        coachRegDate: { displayName: 'Coach Reg', type: 'date', clearable: true },
        agentRegDate: { displayName: 'Agent Reg', type: 'date', clearable: true },
        firstPhysical: { displayName: 'First Physical', type: 'date', clearable: true },
        annualPhysical: { displayName: 'Annual Physical', type: 'date', clearable: true },
        disabilityInsurance: { displayName: 'Disability Insurance', type: 'date', clearable: true },
        taxPaymentPostalCode: { displayName: 'Tax Postal Code', type: 'string', clearable: true, maxLength: 15 },
        bankState: { type: 'string', displayName: 'Bank State', clearable: true, maxLength: 10 },
        bankState2: { type: 'string', displayName: 'Bank State', clearable: true, maxLength: 10 },
        bankState3: { type: 'string', displayName: 'Bank State', clearable: true, maxLength: 10 },
        bankState4: { type: 'string', displayName: 'Bank State', clearable: true, maxLength: 10 },
        payWire: { type: 'string', displayName: 'Pay Wire', clearable: true, maxLength: 1 },
        payCheck: { type: 'string', displayName: 'Pay Check', clearable: true, maxLength: 1 },
        medicalConsent: { displayName: 'Health Authorization', type: 'date', clearable: true },
        topPlayerCyr: { displayName: 'Is TOP Player current year', type: 'string', clearable: true, maxLength: 4 },
        topPlayerPyr: { displayName: 'Is TOP Player past year', type: 'string', clearable: true, maxLength: 4 },
        hippaRevokeDate: { displayName: 'HIPPA revoke', type: 'date', clearable: true },
        upgradeEligible: { displayName: 'Upgrade Eligible', type: 'string', clearable: true, maxLength: 1 },
        eligibleMembership: {
            displayName: 'Eligible Membership',
            type: 'string',
            sysCode: 'MBR_TYPE',
            clearable: true,
            maxLength: 1,
            lookup: [
                { id: 'S', displayName: 'S' },
                { id: 'A', displayName: 'A' },
                { id: 'F', displayName: 'F' },
                { id: 'X', displayName: 'X' },
            ],
        },
        prefBankUsd: {
            displayName: 'Preferred Bank USD',
            type: 'string',
            clearable: true,
            maxLength: 1,
            lookup: [
                { id: '1', displayName: '1' },
                { id: '2', displayName: '2' },
                { id: '3', displayName: '3' },
                { id: '4', displayName: '4' },
            ],
        },
        prefBankEur: {
            displayName: 'Preferred Bank EUR',
            type: 'string',
            clearable: true,
            maxLength: 1,
            lookup: [
                { id: '1', displayName: '1' },
                { id: '2', displayName: '2' },
                { id: '3', displayName: '3' },
                { id: '4', displayName: '4' },
            ],
        },
        prefBankOther: {
            displayName: 'Preferred Bank Other',
            type: 'string',
            clearable: true,
            maxLength: 1,
            lookup: [
                { id: '1', displayName: '1' },
                { id: '2', displayName: '2' },
                { id: '3', displayName: '3' },
                { id: '4', displayName: '4' },
            ],
        },
        termsSDate: { displayName: 'Terms Singles', type: 'date', clearable: true },
        termsDDate: { displayName: 'Terms Doubles', type: 'date', clearable: true },
        playerAge: { type: 'number', displayName: 'Age', group: 'general', sortable: true, readonly: true, clearable: true },
        specialRank: { displayName: 'Special Rank Singles', type: 'number', readonly: true, clearable: true },
        specialDoublesRank: { displayName: 'Special Rank Doubles', type: 'number', readonly: true, clearable: true },
        totalCost: { displayName: 'Total Payment (computed)', type: 'currency', readonly: true },

        //
        // PLAYER_RANK_CURRENT
        //
        singlesRank: { displayName: 'Singles Rank', type: 'number', sortable: true, readonly: true },
        doublesRank: { displayName: 'Doubles Rank', type: 'number', sortable: true, readonly: true },

        //
        // PLAYER_DEVELOPMENT
        //
        currentPhase: {
            type: 'string',
            displayName: 'Current Phase',
            columnName: 'Phase',
            clearable: true,
            readonly: true,
            lookup: [
                { id: '1', displayName: 'Intro' },
                { id: '2', displayName: 'Rookie' },
                { id: '3', displayName: 'Elite' },
                { id: '4', displayName: 'Premier' },
            ],
        },
        courseMill: { displayName: 'Course Mill', type: 'string', clearable: true, readonly: true },
        completedRequirements: {
            displayName: 'Completed Requirements',
            type: 'string-array',
            displayType: 'player-development',
            tagType: 'success',
            alertType: 'success',
            readonly: true,
        },
        outstandingRequirements: {
            displayName: 'Outstanding Requirements',
            type: 'string-array',
            displayType: 'player-development',
            tagType: 'warning',
            alertType: 'warning',
            readonly: true,
        },
        overdueRequirements: {
            displayName: 'Overdue Requirements',
            type: 'string-array',
            displayType: 'player-development',
            tagType: 'danger',
            alertType: 'error',
            readonly: true,
        },
        ppStatus: {
            type: 'string',
            displayName: 'Intro/ Pro Path',
            maxLength: 16,
            readonly: true,
            lookup: [
                { id: 'INTRO', displayName: 'Intro' },
                { id: 'PROPATH', displayName: 'Pro Path' },
            ],
        },
        ph1EligibleDate: { type: 'date', displayName: 'Eligible', clearable: true, columnName: 'Intro', sortable: true }, // used
        ph2EligibleDate: { type: 'date', displayName: 'Eligible', clearable: true, columnName: 'Rookie', sortable: true }, // used
        ph3EligibleDate: { type: 'date', displayName: 'Eligible', clearable: true, columnName: 'Elite', sortable: true }, // used
        ph4EligibleDate: { type: 'date', displayName: 'Eligible', clearable: true, columnName: 'Premier', sortable: true }, // used
        ppEligibleDate: { type: 'date', displayName: 'Eligible', clearable: true, columnName: 'ProPath', sortable: true }, // used
        ph1EligibleDateComputed: { type: 'date', displayName: 'Eligible', clearable: true, columnName: 'Intro comp', sortable: true }, // used
        ph2EligibleDateComputed: { type: 'date', displayName: 'Eligible', clearable: true, columnName: 'Rookie comp', sortable: true }, // used
        ph3EligibleDateComputed: { type: 'date', displayName: 'Eligible', clearable: true, columnName: 'Elite comp', sortable: true }, // used
        ph4EligibleDateComputed: { type: 'date', displayName: 'Eligible', clearable: true, columnName: 'Premier comp', sortable: true }, // used

        //
        // ITF_PLAYER_LINK
        //
        // itfId: { displayName: 'ITF Id', type: 'number', group: 'general', clearable: true, readonly: true, },

        //
        // UNKNOWN
        //
        // rankRangePreviousYear: { displayName: 'Rank Range Prev Year', type: 'string' },
        // guardianName: { displayName: 'Name Of Guardian', type: 'string' },
        // playerZone: { displayName: 'Player Zone', type: 'string' },
        // contact: { displayName: 'Contact', type: 'string' },
        // memberSince: { displayName: 'Member Since', type: 'string' },
        // entryYear: { displayName: 'Entry Year', type: 'string' },
        // wtaReceiveDate: { displayName: 'WTA Received', type: 'string' },
        // processDate: { displayName: 'Process Date', type: 'date' },

        //
        // OWN
        //
        fullName: { displayName: 'Name', type: 'string', sortable: true, readonly: true },
        currency: { type: 'string', displayName: 'Currency', readonly: true },

        //
        // Player Payment fields which appear to be handled by Payment
        // Therefore, not needed
        //
        // playerPaymentCountryCode: { displayName: 'Country Code', type: 'string' },
        // playerPaymentAddress1: { displayName: 'Address 1', type: 'string' },
        // playerPaymentAddress2: { displayName: 'Address 2', type: 'string' },
        // playerPaymentCity: { displayName: 'City', type: 'string' },
        // playerPaymentEmail: { displayName: 'Payment Notification Email', type: 'string' },
        playerAnnualFormDate: { type: 'date', displayName: 'Annual Form', clearable: true},
        playerAnnualFormEmail: { type: 'string', displayName: 'Email', clearable: true, readonly: true },
        playerAnnualFormName: { type: 'string', displayName: 'Signed By', clearable: true },
        playerAnnualFormRelation: { type: 'string', displayName: 'Relation', clearable: true },
        integrityTrainingCompDate: { type: 'date', displayName: ' ITIA Completion Date', clearable: true, readonly: true},
        integrityTrainingExpirationDate: { type: 'date', displayName: 'ITIA Expiration Date', clearable: true, readonly: true},

        playerHipaaFormDate: { type: 'date', displayName: 'Health Authorization', clearable: true },
        playerHipaaFormEmail: { type: 'string', displayName: 'Email', clearable: true },
        playerHipaaFormName: { type: 'string', displayName: 'Signed By', clearable: true },
        playerHipaaFormRelation: { type: 'string', displayName: 'Relation', clearable: true },
    },
};
