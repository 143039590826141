import { isValid, Model } from './model';
import { icoCountries } from '../utils/country-codes';

export class Tournament extends Model {
    isValid() {
        return isValid(Tournament.schema, this);
    }
}

Tournament.layout = {
    children: [
        {
            title: 'General',
            children: ['tournamentId', 'season', 'name'],
        },
        {
            children: ['prize', 'commitment', 'currency'],
        },
        {
            title: 'Dates',
            children: ['startDate', 'endDate', 'startQDate', 'endQDate'],
        },
        {
            children: ['goLiveDate', { type: 'spacer' }],
        },
        {
            children: ['withdrawQDate', 'withdrawMDate', 'withdrawDDate', { type: 'spacer' }],
        },
        {
            children: ['pointsRankDate', 'useForRankingDate', { type: 'spacer', width: 2 }],
        },
        {
            title: 'Details',
            children: ['countryCode', 'region', 'location', 'city', 'setting'],
        },
        {
            children: ['surface', 'surfaceSubCategory', 'surfaceDescription', 'type', 'level'],
        },
        {
            children: ['ballType', 'ballTypeSubCategory', 'slaTournament', 'timeZone', 'standardUtcOffset'],
        },
        {
            children: ['isCombined', 'isCombinedWith', 'expectedSeedRanking', { type: 'spacer' }],
        },
        {
            children: ['sponsor', 'director', { type: 'spacer', width: 0.5 }],
        },
        {
            title: 'Prize details',
            children: ['prizeIdSingles', 'prizeIdDoubles', 'prizeIdQualifyingSingles', 'prizeIdQualifyingDoubles'],
        },
        {
            title: 'Points details',
            children: ['pointIdSingles', 'pointIdDoubles', 'pointIdQualifyingSingles', 'pointsRankDate'],
        },
        {
            title: 'Draw size',
            children: ['singlesMainDrawSize', 'singlesQualificationDrawSize', 'doublesMainDrawSize', { type: 'spacer' }],
        },
        {
            children: ['singlesMainQualifiedCount', 'doublesMainQualifiedCount', 'numberOfSESpotsInUse'],
        },
        {
            children: ['numberOfP_ByesInUse', 'numberOfRoundsInTheQualifyingDraw', { type: 'spacer', width: 2 }],
        },
        {
            title: 'Additional information to be displayed on Playerzone',
            children: ['additionalTournamentInformationSinglesMain', 'additionalTournamentInformationSinglesQualification'],
        },
        {
            children: ['additionalTournamentInformationDoublesMain', { type: 'spacer' }],
        },
        {
            title: 'Special Exemptions',
            children: ['singlesMainExceptionCount', 'doublesMainExceptionCount', 'singlesQualificationExceptionCount', 'doublesQualificationExceptionCount'],
        },
        {
            children: ['exempt1', 'exempt2', 'exempt3', 'exempt4'],
        },
        {
            children: ['exempt1Q', 'exempt2Q', 'exempt3Q', 'exempt4Q'],
        },
        {
            title: 'Byes',
            children: ['singlesMainByeCount', 'doublesMainByeCount', 'singlesQualificationByeCount', 'doublesQualificationByeCount'],
        },
        {
            title: 'Wildcards',
            children: ['singlesMainWildCardCount', 'doublesMainWildcardCount', 'singlesQualificationWildCardCount', { type: 'spacer' }],
        },
        {
            children: ['mainWildcard1', 'mainWildcard2', 'mainWildcard3', 'mainWildcard4'],
        },
        {
            children: ['mainWildcard5', 'mainWildcard6', 'mainWildcard7', 'mainWildcard8'],
        },
        {
            children: ['qualificationWildcard1', 'qualificationWildcard2', 'qualificationWildcard3', 'qualificationWildcard4'],
        },
        {
            children: ['qualificationWildcard5', 'qualificationWildcard6', 'qualificationWildcard7', 'qualificationWildcard8'],
        },
        {
            title: 'Exemption Players',
            children: ['confirmedPlayer1', 'confirmedPlayer2', 'confirmedPlayer3', 'confirmedPlayer4'],
        },
        {
            title: 'Other',
            children: ['finalSetNoTie', { type: 'spacer', width: 3 }],
        },
        {
            children: ['updatedBy', 'updatedAt', 'tournStatus', 'tournStatusD'],
        },
        {
            children: ['liveScoringId', 'liveScoringUsed', { type: 'spacer', width: 2 }],
        },
    ],
};

Tournament.layoutItfImport = {
    children: [
        {
            title: 'General',
            children: ['tournamentId', 'season', 'name'],
        },
        {
            children: ['prize', 'commitment', 'currency'],
        },
        {
            title: 'Dates',
            children: ['startDate', 'endDate', 'startQDate', 'endQDate'],
        },
        {
            title: 'Details',
            children: ['countryCode', 'region', 'location', 'city', { type: 'spacer' }],
        },
        {
            children: ['setting', 'surface', 'surfaceSubCategory', 'type', 'level'],
        },
        {
            children: ['ballType', 'ballTypeSubCategory', 'slaTournament', 'timeZone', 'standardUtcOffset'],
        },        
        {
            children: ['sponsor', 'director', { type: 'spacer', width: 0.5 }],
        },
    ],
};


Tournament.layoutDrawInformation = {
    children: [
        {
            children: ['singlesQualificationDrawSize', 'singlesMainDrawSize', 'doublesMainDrawSize', 'singlesMainQualifiedCount',],
        },
    ]
};

Tournament.schema = {
    fields: {
        tournamentId: { type: 'number', displayName: 'Id', sortable: true, required: true },
        season: { type: 'number', displayName: 'Year', sortable: true, required: true },
        itfId: {
            displayName: 'ITF Id',
            type: 'string',
            group: 'general',
            readonly: true, clearable: true,
        },
        startDate: { type: 'us-date', displayName: 'Start date', sortable: true, required: true, clearable: true },
        endDate: { type: 'us-date', displayName: 'End date', sortable: true, required: true, clearable: true },
        ballType: {
            type: 'string',
            displayName: 'Ball type',
            required: false,
            sysCode: 'BALL_TYPE', clearable: true,
            lookup: [
                { id: 'B', displayName: 'BABOLAT' },
                { id: 'D', displayName: 'DUNLOP' },
                { id: 'E', displayName: 'PRINCE' },
                { id: 'F', displayName: 'TECHNIFIBRE' },
                { id: 'H', displayName: 'HEAD' },
                { id: 'N', displayName: 'N' },
                { id: 'P', displayName: 'PENN' },
                { id: 'R', displayName: 'ROLAND GARROS' },
                { id: 'S', displayName: 'SLAZENGER' },
                { id: 'T', displayName: 'TRETORN' },
                { id: 'W', displayName: 'WILSON' },
                { id: 'X', displayName: 'SRIXON' },
                { id: 'Y', displayName: 'YONEX' },
            ],
        },
        surface: {
            type: 'string',
            displayName: 'Surface',
            required: false, clearable: true,
            lookup: [
                { id: 'CLAY', displayName: 'CLAY' },
                { id: 'GRASS', displayName: 'GRASS' },
                { id: 'HARD', displayName: 'HARD' },
                { id: 'MIXED', displayName: 'MIXED' },
                { id: 'CARPET', displayName: 'CARPET' },
                { id: ' ', displayName: 'X' },
                { id: 'Y', displayName: 'Y' },
            ],
        },
        prize: { type: 'currency', displayName: 'Prize money', clearable: true },
        commitment: { type: 'currency', displayName: 'Commitment', clearable: true },
        currency: {
            type: 'string',
            displayName: 'Currency', clearable: true,
            lookup: [
                { id: 'D', displayName: 'USD' },
                { id: 'E', displayName: 'EUR' },
                { id: 'A', displayName: 'AUD' },
                { id: 'P', displayName: 'GBP' },
                { id: 'No', displayName: 'No' },
            ],
        },
        setting: {
            type: 'string',
            displayName: 'Setting',
            required: true,
            sysCode: 'IN_OUT', clearable: true,
            lookup: [
                { id: 'I', displayName: 'Indoor' },
                { id: 'O', displayName: 'Outdoor' },
            ],
        },
        type: {
            type: 'string',
            displayName: 'Type',
            required: true,
            sortable: true,
            sysCode: 'TOURN_TYPE', clearable: true,
            lookup: [
                { id: 'ST', displayName: 'ITF' },
                { id: 'FC', displayName: 'FedCup' },
                { id: 'GS', displayName: 'GrandSlam' },
                { id: 'CA', displayName: 'Cancelled' },
                { id: 'VS', displayName: 'WTA' },
                { id: 'ES', displayName: 'ES' },
                { id: 'OT', displayName: 'OT' },
            ],
        },
        level: {
            type: 'string',
            displayName: 'Level',
            required: false,
            sortable: true, clearable: true,
            lookup: [
                { id: 'GS', displayName: 'GrandSlam' },
                { id: 'CH', displayName: 'Championship' },
                { id: 'PM', displayName: 'WTA 1000 (PM)' },
                { id: 'P5', displayName: 'WTA 1000 (P5)' },
                { id: 'P', displayName: 'WTA 500 (P)' },
                { id: 'IS', displayName: 'WTA 250 (IS)' },
                { id: 'C', displayName: 'WTA 125' },
                { id: 'R1', displayName: 'FedCupRound1' },
                { id: 'R2', displayName: 'FedCupRound2' },
                { id: 'R3', displayName: 'FedCupSemiFinal' },
                { id: 'R4', displayName: 'FedCupFinal' },
                { id: 'I', displayName: 'TierI' },
                { id: 'II', displayName: 'TierII' },
                { id: 'III', displayName: 'TierIII' },
                { id: 'IV', displayName: 'TierIV' },
                { id: 'V', displayName: 'TierV' },
                { id: 'IN', displayName: 'IN' },
                { id: 'IVA', displayName: 'IVA' },
                { id: 'IVB', displayName: 'IVB' },
            ],
        },
        countryCode: { type: 'nationality', displayName: 'Country', sortable: true, required: true, lookup: Object.keys(icoCountries), clearable: true },
        director: { type: 'string', displayName: 'Director', clearable: true  },        
        isCombined: {
            displayName: 'Is Combined',
            type: 'string',
            group: 'general',            
            required: false,
            clearable: true,
            lookup: [
                { id: true, displayName: 'Yes' },
                { id: false, displayName: 'No' },                
            ],
        },
        isCombinedWith: { type: 'string', displayName: 'Is Combined With', clearable: true  },
        surfaceDescription: { type: 'string', displayName: 'Surface Description', clearable: true  },
        expectedSeedRanking: { type: 'us-date', displayName: 'Expected Seed Ranking Date', clearable: true },
        name: { type: 'string', displayName: 'Name', sortable: true, required: true, clearable: true  },
        sponsor: { type: 'string', displayName: 'Sponsor', clearable: true  },
        singlesMainDrawSize: { type: 'number', displayName: 'Singles Main Draw Size', clearable: true },
        singlesMainWildCardCount: { type: 'number', displayName: 'Singles Main Wildcard Count', clearable: true },
        singlesMainByeCount: { type: 'number', displayName: 'Singles Main Bye Count', clearable: true },
        singlesMainExceptionCount: { type: 'number', displayName: 'Singles Main Exception Count', clearable: true },
        singlesMainQualifiedCount: { type: 'number', displayName: 'Singles Main Qualified Count', clearable: true },
        singlesQualificationDrawSize: { type: 'number', displayName: 'Singles Qualification Draw Size', clearable: true },
        singlesQualificationWildCardCount: { type: 'number', displayName: 'Singles Qualification Wildcard Count', clearable: true },
        singlesQualificationByeCount: { type: 'number', displayName: 'Singles Qualification Bye Count', clearable: true },
        doublesQualificationByeCount: { type: 'number', displayName: 'Doubles Qualification Bye Count', clearable: true },
        singlesQualificationExceptionCount: { type: 'number', displayName: 'Singles Qualification Exception Count', clearable: true },
        doublesQualificationExceptionCount: { type: 'number', displayName: 'Doubles Qualification Exception Count', clearable: true },
        doublesMainDrawSize: { type: 'number', displayName: 'Doubles Main Draw Size', clearable: true },
        doublesMainWildcardCount: { type: 'number', displayName: 'Doubles Main Wildcard Count', clearable: true },
        doublesMainByeCount: { type: 'number', displayName: 'Doubles Main Bye Count', clearable: true },
        doublesMainExceptionCount: { type: 'number', displayName: 'Doubles Main Exception Count', clearable: true },
        doublesMainQualifiedCount: { type: 'number', displayName: 'Doubles Main Qualified Count', clearable: true },
        numberOfSESpotsInUse: { type: 'number', displayName: 'Number Of SE Spots In Use', clearable: true },
        numberOfP_ByesInUse: { type: 'number', displayName: 'Number Of P-Byes In Use', clearable: true },
        numberOfRoundsInTheQualifyingDraw: { type: 'number', displayName: 'Number Of Rounds In The Qualifying Draw', clearable: true },
        mainWildcard1: { type: 'string', displayName: 'Main Wildcard 1', clearable: true },
        mainWildcard2: { type: 'string', displayName: 'Main Wildcard 2', clearable: true },
        mainWildcard3: { type: 'string', displayName: 'Main Wildcard 3', clearable: true },
        mainWildcard4: { type: 'string', displayName: 'Main Wildcard 4', clearable: true },
        mainWildcard5: { type: 'string', displayName: 'Main Wildcard 5', clearable: true },
        mainWildcard6: { type: 'string', displayName: 'Main Wildcard 6', clearable: true },
        mainWildcard7: { type: 'string', displayName: 'Main Wildcard 7', clearable: true },
        mainWildcard8: { type: 'string', displayName: 'Main Wildcard 8', clearable: true },
        qualificationWildcard1: { type: 'string', displayName: 'Qualification Wildcard 1', clearable: true },
        qualificationWildcard2: { type: 'string', displayName: 'Qualification Wildcard 2', clearable: true },
        qualificationWildcard3: { type: 'string', displayName: 'Qualification Wildcard 3', clearable: true },
        qualificationWildcard4: { type: 'string', displayName: 'Qualification Wildcard 4', clearable: true },
        qualificationWildcard5: { type: 'string', displayName: 'Qualification Wildcard 5', clearable: true },
        qualificationWildcard6: { type: 'string', displayName: 'Qualification Wildcard 6', clearable: true },
        qualificationWildcard7: { type: 'string', displayName: 'Qualification Wildcard 7', clearable: true },
        qualificationWildcard8: { type: 'string', displayName: 'Qualification Wildcard 8', clearable: true },
        mainEntryDeadline: { type: 'us-date', displayName: 'Entry deadline', clearable: true },
        qualificationDeadline: { type: 'us-date', displayName: 'Qualification deadline', clearable: true },
        pointsRankDate: { type: 'us-date', displayName: 'Quality Points Rank Date', clearable: true },
        prizeIdSingles: { type: 'string', displayName: 'Singles Prize Id', clearable: true },
        prizeIdDoubles: { type: 'string', displayName: 'Doubles Prize Id', clearable: true },
        prizeIdQualifyingSingles: { type: 'string', displayName: 'Qualifying Singles Prize Id', clearable: true },
        prizeIdQualifyingDoubles: { type: 'string', displayName: 'Qualifying Doubles Prize Id', clearable: true },
        requiredForRankingPlayerCount: { type: 'int', displayName: 'Player Count', clearable: true },
        city: { displayName: 'City', type: 'string', clearable: true },
        startQDate: { displayName: 'Quali Start Date', type: 'date', clearable: true },
        endQDate: { displayName: 'Quali End Date', type: 'date', clearable: true },
        goLiveDate: { displayName: 'Go Live Date', type: 'date', clearable: true },
        region: {
            displayName: 'Region',
            type: 'string', clearable: true,
            lookup: [
                { id: 'AFRI', displayName: 'Africa (AFRI)' },
                { id: 'ASIA', displayName: 'Asia (ASIA)' },
                { id: 'EURO', displayName: 'Europe (EURO)' },
                { id: 'AMCA', displayName: 'Central/ North America (AMCA)' },
                { id: 'SOAM', displayName: 'South America (SOAM)' },
                { id: 'OCEA', displayName: 'Oceania (OCEA)' },
            ],
        },
        timeZone: {
            displayName: 'Time zone',
            type: 'autocomplete', clearable: true,
            autocomplete: [
                { value: 'E. Europe Standard Time' },
                { value: 'Russian Standard Time' },
                { value: 'Taipei Standard Time' },
                { value: 'Pacific Standard Time' },
                { value: 'Central Europe Standard Time' },
                { value: 'Turkey Standard Time' },
                { value: 'China Standard Time' },
                { value: 'E. South America Standard Time' },
                { value: 'Hawaiian Standard Time' },
                { value: 'Central European Standard Time' },
                { value: 'Arab Standard Time' },
                { value: 'Central Standard Time' },
                { value: 'Israel Standard Time' },
                { value: 'Central Standard Time (Mexico)' },
                { value: 'E. Australia Standard Time' },
                { value: 'Singapore Standard Time' },
                { value: 'Romance Standard Time' },
                { value: 'West Asia Standard Time' },
                { value: 'New Zealand Standard Time' },
                { value: 'Tasmania Standard Time' },
                { value: 'Azerbaijan Standard Time' },
                { value: 'FLE Standard Time' },
                { value: 'AUS Eastern Standard Time' },
                { value: 'Arabian Standard Time' },
                { value: 'SE Asia Standard Time' },
                { value: 'India Standard Time' },
                { value: 'GMT Standard Time' },
                { value: 'AUS Central Standard Time' },
                { value: 'Morocco Standard Time' },
                { value: 'E. Australia Standard Time111' },
                { value: 'Korea Standard Time' },
                { value: 'W. Europe Standard Time' },
                { value: 'SA Pacific Standard Time' },
                { value: 'Tokyo Standard Time' },
                { value: 'Eastern Standard Time' },
            ],
        },
        tournStatus: { displayName: 'Publish Status Singles', type: 'string', clearable: true },
        tournStatusD: { displayName: 'Publish Status Doubles', type: 'string', clearable: true },
        useForRankingDate: { displayName: 'Use to Calc Ranking Date', type: 'date', clearable: true },
        withdrawQDate: { displayName: 'Quali Singles Entry Deadline', type: 'date', clearable: true },
        withdrawMDate: { displayName: 'Main Draw Entry Deadline', type: 'date', clearable: true },
        withdrawDDate: { displayName: 'Doubles Entry Deadline', type: 'date', clearable: true },
        pointIdSingles: { displayName: 'Point Id Singles', type: 'string', clearable: true, specialCharacterAllowed: false },
        pointIdDoubles: { displayName: 'Point Id Doubles', type: 'string', clearable: true },
        pointIdQualifyingSingles: { displayName: 'Point Id Qualifying Singles', type: 'string', clearable: true },
        ballTypeSubCategory: { displayName: 'Ball Type sub category', type: 'string', clearable: true },
        slaTournament: { displayName: 'Sla Tournament', type: 'string', clearable: true },
        exempt1: { displayName: 'Special Exempt 1', type: 'string', clearable: true },
        exempt2: { displayName: 'Special Exempt 2', type: 'string', clearable: true },
        exempt3: { displayName: 'Special Exempt 3', type: 'string', clearable: true },
        exempt4: { displayName: 'Special Exempt 4', type: 'string', clearable: true },
        exempt1Q: { displayName: 'Special Exempt 1Q', type: 'string', clearable: true },
        exempt2Q: { displayName: 'Special Exempt 2Q', type: 'string', clearable: true },
        exempt3Q: { displayName: 'Special Exempt 3Q', type: 'string', clearable: true },
        exempt4Q: { displayName: 'Special Exempt 4Q', type: 'string', clearable: true },
        liveScoringId: { displayName: 'Live Scoring Id', type: 'string', clearable: true, specialCharacterAllowed: false },
        liveScoringUsed: { displayName: 'Live Scoring Used', type: 'string', clearable: true, specialCharacterAllowed: false },
        location: { displayName: 'Location', type: 'string', clearable: true  },
        updatedBy: { displayName: 'Updated by', type: 'string', readonly: true, clearable: true },
        updatedAt: { displayName: 'Updated at', type: 'date', readonly: true, clearable: true },
        finalSetNoTie: {
            displayName: 'Final TB',
            type: 'string',
            required: false,
            clearable: true,
            lookup: [
                { id: 'Y', displayName: 'No Tie' },
                { id: 'N', displayName: 'Use' },
            ],
        },
        confirmedPlayer1: { displayName: 'Exemption Player 1', type: 'string', clearable: true },
        confirmedPlayer2: { displayName: 'Exemption Player 2', type: 'string', clearable: true },
        confirmedPlayer3: { displayName: 'Exemption Player 3', type: 'string', clearable: true },
        confirmedPlayer4: { displayName: 'Exemption Player 4', type: 'string', clearable: true },
        surfaceSubCategory: { displayName: 'Surface sub category', type: 'string', clearable: true  },
        standardUtcOffset: { displayName: 'Standard UTC offset', type: 'number', clearable: true },
        additionalTournamentInformationSinglesMain: { displayName: 'Information AL Main Draw Singles', type: 'string', clearable: true },
        additionalTournamentInformationSinglesQualification: { displayName: 'Information AL QLF', type: 'string', clearable: true },
        additionalTournamentInformationDoublesMain: { displayName: 'Information AL Main Draw Doubles', type: 'string', clearable: true },
        dropType: { displayName: 'Drop Type', type: 'string', clearable: true },
    },
};
