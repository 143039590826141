<template>
    <div>
        <div class="flex-row mt-0 mb-32">
            <h2>Player Entry History</h2>
            <span class="spacer"></span>

            <SelectPlayer :value="selectedPlayerFullName" class="mr-8" @palyer-selected="playerSelected" />

            <el-tooltip class="item" effect="dark" content="Select Year" placement="top">
                <el-select v-model="selectedTournamentYear" placeholder="Select Tournament Year">
                  <el-option
                      v-for="year in years"
                      :key="year.id"      
                      :label="year.label"                                     
                      :value="year">
                  </el-option>
                </el-select>
            </el-tooltip>

            <el-button icon="el-icon-search" @click="search"></el-button>

            <el-button v-loading="getDownloadingPdf" class="ml-8" plain size="mini" @click="exportPdf">Export PDF</el-button>
        </div>

        <ErrorDisplay :requestError="this.requestError" @closed="clearError" />

        <DetailedInformation :informationArray="this.informationArray()" screenName="Player Entry History" />

        <div v-loading="getLoadingPlayerEntryHistoryData" class="mt-0 mb-32">
            <DataTable
                :fields="[
                    'playerFirstName',
                    'playerLastName',
                    'entryDate',
                    'entryType',
                    'entryStatus',
                    'entryMode',
                    'entryPreference',
                    'acceptedDate',
                    'tournamentName',
                    'tournamentYear',
                    'startDate'
                ]"
                :items="getPlayerEntryHistoryData.data"
                :schema="schema"
                :sortField="options.sortBy"
                :sortDir="options.sortDir"
                :autoSort="false"
                @header-clicked="setSort"
            />

            <Paginator
                v-if="getPlayerEntryHistoryData.data"
                :data="getPlayerEntryHistoryData"
                :elementsPerPage="options.pageSize"
                @prev-page="prevPage"
                @next-page="nextPage"
                @set-page-size="setPageSize"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { PlayerEntryHistory } from '../models/player-entry-history';
import { retrieveDtos } from '@/utils/crud-confirm';

export default {
    components: {
    },   
    data() {
        return {
            schema: PlayerEntryHistory.schema,
            selectedPlayerFullName: null,           
            requestError: null,
            season: null,            
            playerId: null,
            options: {
                queryString: null,
                sortBy: '',
                sortDir: 'desc',
                page: 0,
                pageSize: 20,
                isEntryHistory: true
            },
            selectedTournamentYear: null,            
            years: this.generateYears(new Date().getFullYear()+1,2005),
           
        };
    },
    mounted() {              
        if(this.playerNbr != null && this.tournamentYear != null){                    
            const body = {
                options: this.options,
                season:   this.tournamentYear,
                playerId: this.playerNbr,
                tournamentId: 0
            };
            let params = {
                self: this,
                fRetrieve: this.findPlayerEntryHistory,
                options: body,
                dtosName: 'Entries History',
            };
            retrieveDtos(params);
        }
    },
    computed: {
        playerNbr() {
                return this.$route.params.playerNbr; // Access the playerId from the route parameters
             },
        tournamentYear() {
                return this.$route.params.tournamentYear; // Access the tournamentYear from the route parameters
        },
        ...mapGetters('playerEntryHistory', ['getLoadingPlayerEntryHistoryData', 'getLoadingError', 'getPlayerEntryHistoryData', 'getDownloadingPdf']),
    },
    methods: {
        ...mapActions('playerEntryHistory', ['findPlayerEntryHistory', 'getPlayerEntryHistoryPdf']),
        playerSelected(player) {
            this.playerId = player.id;
            this.selectedPlayerFullName = player.fullName;
        },     
        generateYears(start, end) {                        
            let idcount=0    
            const years = []; 
            years.push({id: idcount, value: 'Select Tournament Year', label: 'Select Tournament Year'});
            years.push({id: ++idcount, value: idcount, label: 'All Years'});
                for (let year = start; year >= end; year--) {
                    idcount++
                    years.push({ id: idcount, value: ''+year+'', label: ''+year+'' });
                }            
            return years;
        },
        clearError() {
            this.requestError = null;
        },
        setPageSize(size) {
            this.options.pageSize = size;
            this.search();
        },
        prevPage() {
            this.options.page--;
            this.search();
        },
        nextPage() {
            this.options.page++;
            this.search();
        },
        setSort(event) {
            this.options.sortBy = event.property;
            this.options.sortDir = event.order;
            this.search();
        },
        informationArray() {
            return [
                'This screen is used to display Historical Entries for players.',
                'Historical Entries are fetched from table tourn_entry.',
                'Season and tournament are fetched from table tourn_master',
                'Searching is possible by player id <b>or</b> last name <b>or</b> first name. Combined search is not possible.',
                'PDF Export of table is possible by clicking button.',
            ];
        },
        exportPdf() {
            if (!this.selectedPlayerFullName || !this.selectedTournamentYear) {
                this.requestError = {
                    message: 'Choose a player and a tournament year to export pdf',
                };
                return;
            }
            else if (this.selectedTournamentYear.id == 0 ){
                this.requestError = {
                    message: 'Choose a tournament year',
                };
                return;
            }
            let request = {
                season: this.selectedTournamentYear.value,
                playerId: this.playerId
               
            };
            this.downloadPlayerEntryPdf(this.getPlayerEntryHistoryPdf, 'Player_Entry_History.pdf', request);
        },
        downloadPlayerEntryPdf(fDownload, filename, request) {
            this.requestError = null;
            fDownload(request)
                    .then((result) => {
                        const file = new Blob([result], { type: 'application/pdf' });
                        saveAs(file, filename);
                        this.$notify({
                            title: 'PDF download successful',
                            message: filename + ' downloaded',
                            type: 'success',
                            duration: 5000,
                        });
                    })
                    .catch((error) => {
                        this.requestError = error;
                        this.$notify({
                            title: 'PDF download failed',
                            message: filename + ': ',
                            type: 'error',
                            duration: 7000,
                        });
                    });
        },
        search() {                                       
            if (!this.selectedPlayerFullName || !this.selectedTournamentYear) {
                this.requestError = {
                    message: 'Choose a player and a tournament year.',
                };
                return;
            }
            else if (this.selectedTournamentYear.id == 0 ){
                this.requestError = {
                    message: 'Choose a tournament year',
                };
                return;
            }
            const body = {
                options: this.options,
                season:  this.selectedTournamentYear.value,
                playerId: this.playerId,
                tournamentId: 0
            };
            let params = {
                self: this,
                fRetrieve: this.findPlayerEntryHistory,
                options: body,
                dtosName: 'Entries History',
            };
            retrieveDtos(params);
        },
    },
};
</script>

<style scoped>
.input-search {
    max-width: 256px;
}

.year-select {
    max-width: 96px;
}
</style>
