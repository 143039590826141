import { render, staticRenderFns } from "./ProceduresByDeadline.vue?vue&type=template&id=73c9f131&scoped=true"
import script from "./ProceduresByDeadline.vue?vue&type=script&lang=js"
export * from "./ProceduresByDeadline.vue?vue&type=script&lang=js"
import style0 from "./ProceduresByDeadline.vue?vue&type=style&index=0&id=73c9f131&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73c9f131",
  null
  
)

export default component.exports