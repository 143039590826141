import Vue from 'vue';
import Vuex from 'vuex';

import auth from './auth';
import players from './players';
import playerDetails from './player-details';
import playerMembershipHistories from './player-membership-histories';
import playerEntryAndWithdrawalHistory from './Player-entry-and-withdrawal-history';
import playerBonuses from './player-bonuses';
import playerBioDets from './player-bio-dets';
import playerDevelopments from './player-developments';
import playerSupportAgent from './player-support-agent';
import playerSupportCoach from './player-support-coach';
import playerSupportParent from './player-support-parent';
import playerSupportReports from './player-support-reports';
import playerSupport from './player-support';
import playerSupportDetails from './player-support-details';
import playersPlayerPayments from './players-player-payments';
import ageEligibilityTrackings from './age-eligibility-trackings';

import tournaments from './tournaments';
import tournamentDetails from './tournament-details';
import tournamentResults from './tournament-results';
import tournamentRounds from './tournament-rounds';
import singlesEntries from './singles-entries';
import doublesEntries from './doubles-entries';
import mixedDoubles from './mixed-doubles';
import tournamentsPlayerPayments from './tournaments-player-payments';

import importEntries from './import-entries';
import itfImports from '@/store/itf-imports';
import itfPlayerLinks from '@/store/itf-player-links';

import ranking from './ranking';
import currentOverview from './ranking-current/overview';
import currentSingles from './ranking-current/singles-rankings';
import currentDoubles from './ranking-current/doubles-rankings';
import currentChampSingles from './ranking-current/championship-singles';
import currentTeamPoints from './ranking-current/team-points';
import currentRaceSingles from './ranking-current/race-singles-rankings';

import rankingHistoryOverview from './ranking-history/history-overview';
import historySingles from './ranking-history/history-singles';
import historyDoubles from './ranking-history/history-doubles';
import historyChampSingles from './ranking-history/history-championship-singles';
import historyChampDoubles from './ranking-history/history-championship-doubles';
import historyRaceSingles from './ranking-history/history-race-singles';

import shadowRankings from './shadow-rankings';
import shadowRankingPoints from './shadow-ranking-points';
import shadowRankingSummaries from './shadow-ranking-summaries';
import shadowRankingPdfReports from './shadow-ranking-pdf-reports';

import shadowCurrentSingles from './shadow-ranking-current/shadow-singles-rankings';
import shadowCurrentDoubles from './shadow-ranking-current/shadow-doubles-rankings';
import shadowCurrentChampSingles from './shadow-ranking-current/shadow-championship-singles';
import shadowCurrentTeamPoints from './shadow-ranking-current/shadow-team-points';

import shadowRankingHistory from './shadow-ranking-history/shadow-ranking-history';
import shadowHistorySingles from './shadow-ranking-history/shadow-ranking-history-singles';
import shadowHistoryDoubles from './shadow-ranking-history/shadow-ranking-history-doubles';
import shadowHistoryChampSingles from './shadow-ranking-history/shadow-ranking-history-championship-singles';
import shadowHistoryChampDoubles from './shadow-ranking-history/shadow-ranking-history-championship-doubles';

import rankingProcedure from './ranking-procedure';
import playersChangeRequests from '@/store/players-change-requests';

// import requiredTournaments from './required-tournaments';
import requiredTournamentsMain from './ranking-required-tournaments/required-tournaments-main';
import requiredTournamentPlayerList from './ranking-required-tournaments/required-tournament-player-list';

import specialRankingOverviews from './special-ranking-overviews';

import coronaRankingDropDates from './tournament-results-corona-drop-dates';

import acceptanceList from './acceptance-list';
import procedure from './procedure';

import sysCodes from './sys-codes';
import tournamentPrizes from './tournament-prizes';
import tournamentPoints from './tournament-points';

import wtaSystem from './wta-system';
import wtaTourYears from './wta-tour-years';
import playerStats from './player-stats';

import pzMembershipSubscriptions from './player-zone/pz-membership-subscriptions';
import pzPaymentDates from './player-zone/pz-payment-dates';
import pzAccessAgents from './player-zone/pz-access-agents';
import pzAccessCoaches from './player-zone/pz-access-coaches';

import paymentReports from './payment-reports';
import membershipPaymentReports from './membership-payment-reports';


import csvExporter from './csv-exporter';
import pdfReports from './pdf-reports';
import dataExporter from './data-exporter';
import bankingData from './reports/banking-data';
import messageRecipients from './message-recipients';
import playerEntryHistory from './player-entry-history';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth,
        players,
        playerDetails,
        playerMembershipHistories,
        playerEntryAndWithdrawalHistory,
        playerBonuses,
        playerBioDets,
        playerDevelopments,
        playerSupportAgent,
        playerSupportCoach,
        playerSupportParent,
        playerSupport,
        playerSupportDetails,
        playersPlayerPayments,
        ageEligibilityTrackings,
        playerSupportReports,
        messageRecipients,

        tournaments,
        tournamentDetails,
        tournamentResults,
        tournamentRounds,
        mixedDoubles,
        singlesEntries,
        doublesEntries,
        importEntries,
        itfImports,
        itfPlayerLinks,
        tournamentsPlayerPayments,

        ranking,
        currentOverview,
        currentSingles,
        currentDoubles,
        currentChampSingles,
        currentTeamPoints,
        currentRaceSingles,

        rankingHistoryOverview,
        historySingles,
        historyDoubles,
        historyChampSingles,
        historyChampDoubles,
        historyRaceSingles,
        rankingProcedure,

        shadowRankings,
        shadowRankingPoints,
        shadowRankingSummaries,
        shadowRankingPdfReports,

        shadowCurrentSingles,
        shadowCurrentDoubles,
        shadowCurrentChampSingles,
        shadowCurrentTeamPoints,

        shadowRankingHistory,
        shadowHistorySingles,
        shadowHistoryDoubles,
        shadowHistoryChampSingles,
        shadowHistoryChampDoubles,

        // requiredTournaments,
        requiredTournamentsMain,
        requiredTournamentPlayerList,

        specialRankingOverviews,

        coronaRankingDropDates,

        acceptanceList,
        procedure,

        playerEntryHistory,

        sysCodes,
        tournamentPrizes,
        tournamentPoints,

        wtaSystem,
        wtaTourYears,
        playerStats,
        playersChangeRequests,

        pzMembershipSubscriptions,
        pzPaymentDates,
        pzAccessAgents,
        pzAccessCoaches,
        

        paymentReports,
        membershipPaymentReports,        

        csvExporter,
        pdfReports,
        dataExporter,
        bankingData,
    },
});
