import { Model, isValid } from './model';

export class PlayerEntryAndWithdrawalHistory extends Model {
    isValid() {
        return isValid(PlayerEntryAndWithdrawalHistory.schema, this);
    }
}

PlayerEntryAndWithdrawalHistory.layout = {  
};

PlayerEntryAndWithdrawalHistory.schema = {
    fields: {
        recKey: { type: 'number', displayName: 'Rec Key', readonly: true },
        playerNumber: { type: 'player', displayName: 'Player Id', readonly: true },       
        entryDate: { type: 'us-date', displayName: 'Entry Date', readonly: true, sortable: true },        
        entryStatus: { type: 'string', displayName: 'Entry Status', readonly: true, sortable: true },        
        tournamentNumber: { type: 'number', displayName: 'Tournament Id', readonly: true },
        tournamentYear: { type: 'number', displayName: 'Tournament Year', readonly: true, sortable: true },
        tournamentName: { type: 'string', displayName: 'Tournament Name', readonly: true, sortable: true },        
        withDrawalDate: { type: 'us-date', displayName: 'Withdrawal Date', required: true},                
    },
};
