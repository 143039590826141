import { Model, isValid } from './model';

export class PlayerMembershipHistory extends Model {
    isValid() {
        return isValid(PlayerMembershipHistory.schema, this);
    }
}

PlayerMembershipHistory.layout = {
    children: [
        {
            children: ['playerNbr', { type: 'spacer', width: 2 }],
        },        
        {
            children: ['benefitYear', 'membershipType', { type: 'spacer' }],
        },
    ],
};

PlayerMembershipHistory.schema = {
    fields: {
        playerNbr: { type: 'player', displayName: 'Player', required: true, readonly:true },
        benefitYear: { type: 'number', displayName: 'Benefit Year', required: true },        
        membershipType: {
            displayName: 'Membership Type',
            type: 'string',
            required: true,
            sysCode: 'MBR_TYPE',
            lookup: [
                { id: 'S', displayName: 'S' },
                { id: 'A', displayName: 'A' },
                { id: 'X', displayName: 'X' },
                { id: 'F', displayName: 'F' },
            ],
        },      
    },
};
