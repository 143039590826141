import axios from 'axios';

export default {
    namespaced: true,

    state: {
        playerMembershipHistories: [],
        loadingPlayerMembershipHistories: false,
        loadingError: null,
        savingPlayerMembershipHistory: false,
        savingError: null,
        deletingPlayerMembershipHistory: false,
        deletingError: null,
    },

    getters: {
        playerMembershipHistories: (state) => state.playerMembershipHistories,
        loadingPlayerMembershipHistories: (state) => state.loadingPlayerMembershipHistories,
        loadingError: (state) => state.loadingError,
        savingPlayerMembershipHistory: (state) => state.savingPlayerMembershipHistory,
        savingError: (state) => state.savingError,
        deletingPlayerMembershipHistory: (state) => state.deletingPlayerMembershipHistory,
        deletingError: (state) => state.deletingError,
    },

    mutations: {
        setPlayerMembershipHistories(state, data) {
            state.playerMembershipHistories = data;
        },

        startLoadingPlayerMembershipHistories(state) {
            state.loadingPlayerMembershipHistories = true;
        },

        stopLoadingPlayerMembershipHistories(state) {
            state.loadingPlayerMembershipHistories = false;
        },

        setLoadingError(state, error) {
            state.loadingError = error;
        },

        clearLoadingError(state) {
            state.loadingError = null;
        },

        startSavingPlayerMembershipHistory(state) {
            state.savingPlayerMembershipHistory = true;
        },

        stopSavingPlayerMembershipHistory(state) {
            state.savingPlayerMembershipHistory = false;
        },

        setSavingError(state, error) {
            state.savingError = error;
        },

        clearSavingError(state) {
            state.savingError = null;
        },

        startDeletingPlayerMembershipHistory(state) {
            state.deletingPlayerMembershipHistory = true;
        },

        stopDeletingPlayerMembershipHistory(state) {
            state.deletingPlayerMembershipHistory = false;
        },

        setDeletingError(state, error) {
            state.deletingError = error;
        },

        clearDeletingError(state) {
            state.deletingError = null;
        },
    },

    actions: {
        async findPlayerMembershipHistories(context, options = {}) {                     
            context.commit('startLoadingPlayerMembershipHistories');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/PlayerMembershipHistory/${options.playerNbr}`, {
                    params: {
                        queryString: options.queryString || '',
                        pageSize: options.pageSize || 20,
                        page: options.page,
                        sortBy: options.sortBy || 'benefitYear',
                        sortDir: options.sortDir === 'descending' ? -1 : 1,
                    },
                });
                context.commit('stopLoadingPlayerMembershipHistories');
                context.commit('setPlayerMembershipHistories', result.data);
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('stopLoadingPlayerMembershipHistories');
                context.commit('setLoadingError', error);
                return Promise.reject(error);
            }
        },

        async createPlayerMembershipHistory(context, data) {
            context.commit('startSavingPlayerMembershipHistory');
            try {
                const result = await axios.post(`${process.env.VUE_APP_API_URL}/PlayerMembershipHistory`, data.data);
                context.commit('stopSavingPlayerMembershipHistory');                
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('stopSavingPlayerMembershipHistory');
                context.commit('setSavingError', error);
                return Promise.reject(error);
            }
        },

        async updatePlayerMembershipHistory(context, data) {            
            context.commit('startSavingPlayerMembershipHistory');
            try {
                const result = await axios.put(`${process.env.VUE_APP_API_URL}/PlayerMembershipHistory`, {
                    oldPlayerMembershipHistory: data.original,
                    newPlayerMembershipHistory: data.data,
                });
                context.commit('stopSavingPlayerMembershipHistory');                
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('stopSavingPlayerMembershipHistory');
                context.commit('setSavingError', error);
                return Promise.reject(error);
            }
        },

        async deletePlayerMembershipHistory(context, data) {
            context.commit('startDeletingPlayerMembershipHistory');
            try {
                await axios.post(`${process.env.VUE_APP_API_URL}/PlayerMembershipHistory/Delete`, data.data);
                context.commit('stopDeletingPlayerMembershipHistory');                
                return Promise.resolve();
            } catch (error) {
                context.commit('stopDeletingPlayerMembershipHistory');
                context.commit('setDeletingError', error);
                return Promise.reject(error);
            }
        },       
    },
};
