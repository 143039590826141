var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('div',{staticClass:"flex-row mt-0 mb-32"},[_c('h2',[_vm._v("Administration")]),_c('span',{staticClass:"spacer"}),_c('el-button',{staticClass:"mr-8",attrs:{"size":"mini","plain":"","type":"primary"},on:{"click":_vm.filterAll}},[_vm._v("ALL")]),_vm._v(" | "),_c('el-button',{staticClass:"mr-8",attrs:{"size":"mini","plain":"","type":"primary"},on:{"click":_vm.filterEntry}},[_vm._v("ENTRIES")]),_vm._v(" | "),_c('el-button',{staticClass:"mr-8",attrs:{"size":"mini","plain":"","type":"primary"},on:{"click":_vm.filterWithdrawal}},[_vm._v("WITHDRAWALS")])],1),_c('hr',{staticClass:"divider"}),_c('ErrorDisplay',{attrs:{"requestError":this.requestError},on:{"closed":_vm.clearError}}),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.getLoadingPlayerEntryAndWithdrawalHistoryData),expression:"getLoadingPlayerEntryAndWithdrawalHistoryData"}],staticClass:"mt-0 mb-32"},[_c('DataTable',{attrs:{"fields":[                    
                'tournamentName',                  
                'entryStatus',
                'entryDate'
            ],"items":_vm.getPlayerEntryAndWithdrawalHistoryData.data,"schema":_vm.schema,"sortField":_vm.options.sortBy,"sortDir":_vm.options.sortDir,"autoSort":false},on:{"header-clicked":_vm.setSort}}),(_vm.getPlayerEntryAndWithdrawalHistoryData.data)?_c('Paginator',{attrs:{"data":_vm.getPlayerEntryAndWithdrawalHistoryData,"elementsPerPage":_vm.options.pageSize},on:{"prev-page":_vm.prevPage,"next-page":_vm.nextPage,"set-page-size":_vm.setPageSize}}):_vm._e()],1),_vm._m(1),_c('hr',{staticClass:"divider"}),_c('div',{staticClass:"flex-row mt-0 mb-32"},[_c('el-button',{staticClass:"mr-8",attrs:{"size":"max","plain":"","type":"primary"},on:{"click":_vm.redirectPlayerEntryHistoryReport}},[_vm._v("Player Entry History Report")]),_vm._v(" | "),_c('el-button',{staticClass:"mr-8",attrs:{"size":"max","plain":"","type":"primary"},on:{"click":_vm.redirectWithdrawalReasonReport}},[_vm._v("Withdrawal Reason Report")])],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-row mt-0 mb-32"},[_c('h2',[_vm._v("Current Season Entry/Withdrawal")]),_c('span',{staticClass:"spacer"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-row mt-0 mb-32"},[_c('h2',[_vm._v("More Information")]),_c('span',{staticClass:"spacer"})])
}]

export { render, staticRenderFns }